.template {
  border-collapse: collapse;
  border-radius: 4px;
  overflow-y: auto;
}

.template tr {
  background: hsl(0, 0%, 99%);
}

.template th,
.template td {
  padding: 16px;
  border: none;
  font-family: sans-serif;
  font-weight: bold;
}

.template th {
  border-bottom: 1px solid hsl(0, 0%, 95%);
}

.template td {
  color: hsl(0, 0%, 35%);
  border-bottom: 1px solid hsl(0, 0%, 95%);
}
.template tr:last-child td {
  border-bottom: 1px solid hsl(0, 0%, 95%);
}

.template td:last-child {
  text-align: right;
}
