.layout-center {
  min-height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr minmax(min-content, 900px) 1fr;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    ". actions ."
    ". content .";
}

.layout-center__actions {
  max-width: 900px;
  grid-area: actions;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.layout-center__content {
  max-width: 900px;
  grid-area: content;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1452px) {

  .layout-center {
    padding: 0 24px 24px 0;
  }

  .layout-center {
    padding-right: 92px;
  }

}
