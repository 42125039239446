.workspace {
  background: hsl(0, 0%, 98%);
  background: linear-gradient(162deg, hsl(0deg 0% 100%) 0%, hsl(199deg 98% 97%) 100%);
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    "header"
    "main";
}

.workspace__header {
  grid-area: header;
  border-bottom: 1px solid hsl(0, 0%, 90%);
  height: 65px;
}

.workspace__nav {
  display: none;
}

.workspace__nav-mobile {
  padding: 8px 16px 16px 16px;
}

.workspace__main {
  grid-area: main;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.workspace__main::-webkit-scrollbar {
  display: none;
}

@media (min-width: 680px) {

  .workspace__nav {
    display: block;
  }

  .workspace__nav-mobile {
    display: none;
  }

}
