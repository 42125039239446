
.header {
  display: flex;
  justify-content: center;
  font-family: sans-serif;
  background: #fff;
  padding: 16px 40px;
  justify-content: space-between;
}

.main {
  min-height: calc(100vh - 68px);
  flex: 1;
  background: hsl(0, 0%, 100%);
}

.hero {
  height: 90vh;
  padding: 40px 40px 20px;
}

.hero-inner {
  height: 100%;
  background: #fff;
  background: linear-gradient(162deg, hsl(0deg 0% 100%) 0%, hsl(199deg 98% 97%) 100%);
  display: flex;
  align-items: center;
  padding: 64px;
}

.slogan {
  font-size: 64px;
  line-height: 1.2 !important;
}

.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 40px;
  padding: 20px 40px;
}

.feature {
  font-family: sans-serif;
  background: #fff;
  padding: 40px;
  text-align: center;
  background: linear-gradient(162deg, hsl(0deg 0% 100%) 0%, hsl(199deg 98% 97%) 100%);
}
