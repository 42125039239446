* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.z-modal { z-index: 20; }
.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }
.cursor-pointer { cursor: pointer; }

.bg-color-background { background-color: hsl(0, 0%, 95%) }

.bg-gradient-blue {
  background: linear-gradient(162deg, hsl(0, 0%, 100%) 0%, hsl(199deg 98% 97%) 100%);
}
.bg-gradient-orange-blue {
  background: linear-gradient(321deg, hsl(200, 100%, 97%) 35%, hsl(15, 100%, 95%) 100%);
}
.hover\:bg-color-background:hover { background-color: hsl(0, 0%, 95%) !important; }
.bg-color-foreground { background-color: hsl(0, 0%, 99%) !important; }
.hover\:bg-color-foreground:hover { background-color: hsl(0, 0%, 99%) }
.bg-color-affirmative { background-color: hsl(167, 89%, 43%) }
.bg-color-grey97 { background-color: hsl(0, 0%, 97%); }
.hover\:bg-red43:hover { background-color: hsl(0, 89%, 43%) }

.hover\:bg-90:hover {
  background-color: hsl(0, 0%, 90%);
  transition: background-color .5s;
}

.hover\:bg-96:hover {
  background-color: hsl(0, 0%, 96%);
  transition: background-color .5s;
}

.hover\:bg-97:hover {
  background-color: hsl(0, 0%, 97%) !important;
  transition: background-color .5s;
}


.hover\:fill-50:hover,
.fill-50 { fill: hsl(0, 0%, 50%); }
.fill-75 { fill: hsl(0, 0%, 75%); }
.color-40 { color: hsl(0, 0%, 40%); }
.color-75 { color: hsl(0, 0%, 75%); }
.hover\:color-white:hover { color: hsl(0, 0%, 100%) !important; }

.border-bottom-95 { border-bottom: 1px solid hsl(0, 0%, 95%); }
.border-85 { border: 1px solid hsl(0, 0%, 85%); }
.border-bottom-85 { border-bottom: 1px solid hsl(0, 0%, 85%); }
.border-top-85 { border-top: 1px solid hsl(0, 0%, 85%); }
.border-left-85 { border-left: 1px solid hsl(0, 0%, 85%); }

.border-radius-xs { border-radius: 4px; }
.border-radius-s { border-radius: 8px; }
.border-radius-m { border-radius: 16px; }

.h-screen { height: 100vh; }
.h-100 { height: 100%; }
.h-50 { height: 50%; }
.w-100 { width: 100%; }
.vh-100 { height: 100vh; }
.vw-100 { width: 100vw; }

.position-absolute { position: absolute; }
.position-relative { position: relative; }
.p-absolute { position: absolute; }
.p-relative { position: relative; }
.p-fixed { position: fixed; }
.p-sticky { position:sticky; }

.left-0 { left: 0; }
.top-100 { top: 100%; }
.top-0 { top: 0; }
.bottom-0 { bottom: 0; }

.d-flex { display: flex; }
.d-inline { display: inline; }
.d-inline-flex { display: inline-flex; }
.d-block { display: block; }
.d-inline-block { display: inline-block; }
.d-none { display: none; }
.flex-wrap { flex-wrap: wrap; }
.flex-column { flex-direction: column }
.flex-basis-50 { flex-basis: 50% }
.flex-basis-100 { flex-basis: 100% }
.flex-grow { flex-grow: 1 }
.flex-fill {
  flex-grow: 1;
  flex-shrink: 0;
}

.justify-between { justify-content: space-between }
.justify-around { justify-content: space-around }
.justify-center { justify-content: center }
.justify-end { justify-content: flex-end }
.justify-start { justify-content: flex-start; }

.align-center { align-items: center; }
.align-stretch { align-items: stretch; }
.align-start { align-items: flex-start; }
.align-end { align-items: flex-end; }
.align-self-start { align-self: flex-start; }
.align-self-end { align-self: flex-end; }

.cursor-pointer { cursor: pointer }

.inset-stretch-m { padding: 16px 32px }

.inset-xs { padding: 4px }
.inset-s { padding: 8px }
.inset-m { padding: 16px }
.inset-l { padding: 24px }
.inset-xl { padding: 64px }

.inline-xs { margin-right: 4px }
.inline-s { margin-right: 8px }
.inline-m { margin-right: 16px }
.inline-l { margin-right: 24px }
.inline-xl { margin-right: 64px }

.stack-s { margin-bottom: 8px }
.stack-m { margin-bottom: 16px }
.stack-l { margin-bottom: 24px }
.stack-xl { margin-bottom: 32px }
.stack-xxl { margin-bottom: 64px }

.ml-s { margin-left: 8px }
.mr-s { margin-right: 8px }

.shadow-m { box-shadow: 3px 0 10px hsla(0, 0%, 85%, .75) !important; }

.sans-serif { font-family: sans-serif; }
.bold { font-weight: bold; }
.font-size-18 { font-size: 18px; }
.line-height-18 { line-height: 18px; }

.overflow-hidden { overflow: hidden; }
.scroll {
  /* if either overflow-x or overflow-y is set, the oposite property gets set to auto.
  this results in elements being 'cut off' if you don't explicitly set values for both axis*/
  overflow-y: auto;
  overflow-x: visible;
}

.hover\:shadow-focus-m:hover {
    box-shadow: 0 0 10px hsla(199, 98%, 43%, .75) !important;
    transition: box-shadow .5s;
}

.rotate {
  animation-name: rotate;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier;
  animation-iteration-count: infinite;
}

@keyframes rotate {
  from { transform: rotate(0); }
  to { transform: rotate(-360deg); }
}
